import { translate } from 'app/core';
import { Label } from 'nhsuk-react-components';
import React from 'react';
import referralProcessImage from 'assets/images/referral-process.jpg';
import ReactPlayer from "react-player";

const PatientInformationSectionComponent = (): JSX.Element => {
  const slugTranslate = 'HOME.REFERRALS_SECTION';
  return (
    <div className="mx-4">
      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.EMAIL_REFERRAL_FORM`)}
      </Label>
      <Label size="m" className="purple nhsuk-u-margin-bottom-3">
        <a href={`mailto:${translate(`${slugTranslate}.EMAIL_REFERRAL_FORM_EMAIL`)}`} className='nhsuk-link purple'>{translate(`${slugTranslate}.EMAIL_REFERRAL_FORM_EMAIL`)}</a>
      </Label>
      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.REFERRALS_PROCESS`)}
      </Label>
      <img width="40%" src={referralProcessImage} alt={translate('IMAGE.REFERRAL_PROCESS')} />
      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-4'>
        {translate(`${slugTranslate}.RECORDS_SEARCH`)}
      </Label>

      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>
          <a href="/assets/Aversa_Instructions_Searches_SystmOne.docx" download className='nhsuk-link' target="_blank">{translate(`${slugTranslate}.RECORDS_SEARCH_LIST_ITEM_1_A`)}</a>
          <br />
          <a href="/assets/Aversa_Search_SystmOne.rpt" download className='nhsuk-link' target="_blank">{translate(`${slugTranslate}.RECORDS_SEARCH_LIST_ITEM_1_B`)}</a>
        </li>
        <li>
          <a href="/assets/Aversa_Instructions_Searches_EMIS.docx" download className='nhsuk-link' target="_blank">{translate(`${slugTranslate}.RECORDS_SEARCH_LIST_ITEM_2_A`)}</a>
          <br />
          <a href="/assets/Aversa_Search_EMIS.xml" download className='nhsuk-link' target="_blank">{translate(`${slugTranslate}.RECORDS_SEARCH_LIST_ITEM_2_B`)}</a>
        </li>
      </ul>

      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.SEND_SMS`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.SEND_SMS_INTRO`)}
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        <a href="/assets/Aversa_SMS_Invitation_Text.docx" download className='nhsuk-link nhsuk-u-padding-top-3' target="_blank">{translate(`${slugTranslate}.SEND_SMS_ITEM_1_A`)}</a>
        {' '}
        {translate(`${slugTranslate}.SEND_SMS_ITEM_1_B`)}
      </p>

      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.REFERRAL_FORMS`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.REFERRAL_FORMS_ITEM_1`)}
      </p>
      <ul className='nhsuk-list- nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>
          {translate(`${slugTranslate}.REFERRAL_FORMS_ITEM_1_LIST_ITEM_1`)}
        </li>
        <li>
          {translate(`${slugTranslate}.REFERRAL_FORMS_ITEM_1_LIST_ITEM_2`)}
        </li>
        <li>
          {translate(`${slugTranslate}.REFERRAL_FORMS_ITEM_1_LIST_ITEM_3`)}
        </li>
      </ul>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.REFERRAL_FORMS_ITEM_2`)}
      </p>
        
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>
          <a href="/assets/Aversa_Instructions_Ref_Form_SystmOne.docx" download className='nhsuk-link' target="_blank">{translate(`${slugTranslate}.REFERRAL_FORMS_LIST_ITEM_1_A`)}</a>
          <br />
          <a href="/assets/Aversa_Ref_Form_SystmOne.xml" download className='nhsuk-link' target="_blank">{translate(`${slugTranslate}.REFERRAL_FORMS_LIST_ITEM_1_B`)}</a>
        </li>
        <li className="nhsuk-u-margin-top-3">
          <a href="/assets/Aversa_Instructions_Ref_Form_EMIS.docx" download className='nhsuk-link' target="_blank">{translate(`${slugTranslate}.REFERRAL_FORMS_LIST_ITEM_2_A`)}</a>
          <br />
          <a href="/assets/Aversa_Ref_Form_Emis.ewdt" download className='nhsuk-link' target="_blank">{translate(`${slugTranslate}.REFERRAL_FORMS_LIST_ITEM_2_B`)}</a>
        </li>
        <li>
          {translate(`${slugTranslate}.REFERRAL_FORMS_LIST_ITEM_3`)}
        </li>
      </ul>

      <div className='video-wrapper'>
        <ReactPlayer
          className='react-player video-player'
          width='100%'
          height='100%'
          url="assets/Completing Aversa Referral Form.mp4"
          controls
        />
      </div>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-5">
        <span className="nhsuk-u-font-weight-bold purple">
          {translate(`${slugTranslate}.REFERRAL_FORMS_LIST_ITEM_3_WARNING`)}
        </span>
      </p>

      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.PROVIDE_WRITTEN_INSTRUCTIONS_MEDICATION`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.PROVIDE_WRITTEN_INSTRUCTIONS_MEDICATION_DESCRIPTION_1`)}
        &nbsp;
        <span className="nhsuk-u-font-weight-bold">
          {translate(`${slugTranslate}.PROVIDE_WRITTEN_INSTRUCTIONS_MEDICATION_DESCRIPTION_2`)}
          .
        </span>
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        <span className="nhsuk-u-font-weight-bold purple">
          {translate(`${slugTranslate}.PROVIDE_WRITTEN_INSTRUCTIONS_MEDICATION_DESCRIPTION_3`)}
        </span>
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        <span className="nhsuk-u-font-weight-bold purple">
          {translate(`${slugTranslate}.PROVIDE_WRITTEN_INSTRUCTIONS_MEDICATION_DESCRIPTION_4`)}
        </span>
      </p>
      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.CHECK_FILLED_IN`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.CHECK_FILLED_IN_DESCRIPTION`)}
      </p>
      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.SEND_REFERRAL_FORM`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.SEND_REFERRAL_FORM_DESCRIPTION`)}
      </p>
      <Label size="m" className="purple nhsuk-u-margin-bottom-3">
        <a href={`mailto:${translate(`${slugTranslate}.EMAIL_REFERRAL_FORM_EMAIL`)}`} className='nhsuk-link purple'>{translate(`${slugTranslate}.EMAIL_REFERRAL_FORM_EMAIL`)}</a>
      </Label>
    </div>
  );
};

export default PatientInformationSectionComponent;
