import React from 'react';
import {Label} from "nhsuk-react-components";
import {translate} from "../../../core";
import aversaDataCollectionSchedule from "../../../../assets/images/aversa-data-collection-schedule.png";

const ServiceEvaluationComponent = (): JSX.Element => {
  const slugTranslate = 'HOME.SERVICE_EVALUATION';
  return (
    <div className="mx-4">
      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.DATA_COLLECTION_SCHEDULE`)}
      </Label>
      <img src={aversaDataCollectionSchedule} alt={translate('HEADER.LOGO_AVERSA')} />
      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.SUMMARY`)}
      </Label>
      <Label size="s" className='nhsuk-u-margin-top-4'>
        {translate(`${slugTranslate}.SUMMARY_ITEM_1`)}
      </Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.SUMMARY_ITEM_1_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.SUMMARY_ITEM_1_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.SUMMARY_ITEM_1_LIST_3`)}</li>
      </ul>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-5">
        {translate(`${slugTranslate}.SUMMARY_ITEM_1_FOOTER`)}
      </p>
      <Label size="s" className='nhsuk-u-margin-top-4'>
        {translate(`${slugTranslate}.SUMMARY_ITEM_2`)}
      </Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.SUMMARY_ITEM_2_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.SUMMARY_ITEM_2_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.SUMMARY_ITEM_2_LIST_3`)}</li>
      </ul>
      <Label size="s" className='nhsuk-u-margin-top-4'>
        {translate(`${slugTranslate}.SUMMARY_ITEM_3`)}
      </Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.SUMMARY_ITEM_3_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.SUMMARY_ITEM_3_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.SUMMARY_ITEM_3_LIST_3`)}</li>
        <li>{translate(`${slugTranslate}.SUMMARY_ITEM_3_LIST_4`)}</li>
      </ul>
      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.REPORTING`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-5">
        {translate(`${slugTranslate}.REPORTING_INTRO`)}
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-5">
        {translate(`${slugTranslate}.REPORTING_ITEM_1`)}
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.REPORTING_ITEM_1_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.REPORTING_ITEM_1_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.REPORTING_ITEM_1_LIST_3`)}</li>
      </ul>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-5">
        {translate(`${slugTranslate}.REPORTING_ITEM_2`)}
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.REPORTING_ITEM_2_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.REPORTING_ITEM_2_LIST_2`)}</li>
      </ul>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-5">
        {translate(`${slugTranslate}.REPORTING_OUTRO`)}
      </p>
      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.SENDING_UPDATED_DATA`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-5">
        {translate(`${slugTranslate}.SENDING_UPDATED_DATA_ITEM_1`)}
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>
          <a href="/assets/Aversa_LCD_Patient_Update_Form_SystmOne_280423.xml" download className='nhsuk-link' target="_blank">{translate(`${slugTranslate}.SENDING_UPDATED_DATA_ITEM_2_LINK_1`)}</a>
        </li>
        <li className="nhsuk-u-margin-top-3">
          <a href="/assets/Aversa_LCD_Patient_Update_Form_EmisWeb_280423.ewdt" download className='nhsuk-link' target="_blank">{translate(`${slugTranslate}.SENDING_UPDATED_DATA_ITEM_2_LINK_2`)}</a>
        </li>
      </ul>
    </div>
  );
};

export default ServiceEvaluationComponent;
