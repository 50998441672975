import React, { useState } from 'react';

import './Home.component.scss';
import { translate } from 'app/core';
import { Label } from 'nhsuk-react-components';
import AboutAversaComponent from "./AboutAversaSection/AboutAversaSection";
import EligibilityComponent from './EligibilitySection/EligibilitySection';
import MedicationAdjustmentComponent from './MedicationAdjustmentSection/MedicationAdjustmentSection';
import ReferralsComponent from './ReferralsSection/ReferralsSection';
import ServiceEvaluationComponent from './ServiceEvaluationSection/ServiceEvaulationSection';


type TypeTab = 'about_aversa_section' | 'eligibility_section' | 'referrals_section' | 'medication_adjustment_section' | 'service_evaluation_section';

const HomeComponent = (): JSX.Element => {
  const [tabSelected, setTabSelected] = useState<TypeTab>('about_aversa_section');

  return (
    <div className="nhsuk-u-margin-bottom-4">
      <div className="bg-white w-full mx-auto p-4 lg:p-8 rounded shadow-xl lg:max-w-5xl">
        <Label size="l" className='purple'>{translate('HOME.TITLE')}</Label>
        <div>
          {/* className="ml-2 lg:ml-1 h-20 lg:h-24 lg:py-1" */}
        </div>

        <ul className="tabs">
          <li className={`tab ${tabSelected === 'about_aversa_section' ? 'tab-checked' : ''}`}>
            <a href="#about_aversa_section" onClick={(): void => setTabSelected('about_aversa_section')}>
              {translate('HOME.ABOUT_AVERSA_SECTION_TAB')}
            </a>
          </li>
          <li className={`tab ${tabSelected === 'eligibility_section' ? 'tab-checked' : ''}`}>
            <a href="#eligibility_section" onClick={(): void => setTabSelected('eligibility_section')}>
              {translate('HOME.ELIGIBILITY_SECTION_TAB')}
            </a>
          </li>
          <li className={`tab ${tabSelected === 'referrals_section' ? 'tab-checked' : ''}`}>
            <a href="#referrals_section" onClick={(): void => setTabSelected('referrals_section')}>
              {translate('HOME.REFERRALS_SECTION_TAB')}
            </a>
          </li>
          <li className={`tab ${tabSelected === 'medication_adjustment_section' ? 'tab-checked' : ''}`}>
            <a href="#medication_adjustment_section" onClick={(): void => setTabSelected('medication_adjustment_section')}>
              {translate('HOME.MEDICATION_ADJUSTMENT_SECTION_TAB')}
            </a>
          </li>
          <li className={`tab ${tabSelected === 'service_evaluation_section' ? 'tab-checked' : ''}`}>
            <a href="#service_evaluation_section" onClick={(): void => setTabSelected('service_evaluation_section')}>
              {translate('HOME.SERVICE_EVALUATION_SECTION_TAB')}
            </a>
          </li>
        </ul>
        {tabSelected === 'about_aversa_section' && (
        <div className="tab-body" id="about_aversa_section">
          <AboutAversaComponent />
        </div>
              )}
        {tabSelected === 'eligibility_section' && (
        <div className="tab-body" id="eligibility_section">
          <EligibilityComponent />
        </div>
              )}
        {tabSelected === 'referrals_section' && (
        <div className="tab-body" id="referrals_section">
          <ReferralsComponent />
        </div>
              )}

        {tabSelected === 'medication_adjustment_section' && (
        <div className="tab-body" id="medication_adjustment_section">
          <MedicationAdjustmentComponent />
        </div>
              )}

        {tabSelected === 'service_evaluation_section' && (
        <div className="tab-body" id="service_evaluation_section">
          <ServiceEvaluationComponent />
        </div>
              )}
      </div>
    </div>
  );
};

export default HomeComponent;
