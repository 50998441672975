const translations = {
  EMAIL_AVERSA: 'aversa@support.changinghealth.com',
  NOT_SUPPORT_VIDEO: 'Your browser does not support the video tag.',
  FORM: {
    PLEASE_SELECT: 'Please Select',
    PLEASE_CORRECT_FORM_ERRORS: 'Please correct the form errors'
  },
  HOME: {
    CLICK: 'Click Me',
    LOGIN: 'Login',
    ALREADY_REGISTERED: 'Already registered?',
    LOGIN_TO_AVERSA: 'Login to Aversa by clicking the button below. The link will take you to the login page of our programme partner Changing Health.',
    YOU_WILL_NEED: 'You will need your username and password to access the programme.',
    BANNER: 'Working together towards a strong and independent future',
    TITLE: 'Welcome to the Aversa information page for participating GP practices/PCNs',
    ABOUT_AVERSA_SECTION_TAB: 'About Aversa',
    ELIGIBILITY_SECTION_TAB: 'Eligibility',
    REFERRALS_SECTION_TAB: 'Referrals',
    MEDICATION_ADJUSTMENT_SECTION_TAB: 'Meds adjustments & monitoring',
    SERVICE_EVALUATION_SECTION_TAB: 'Service evaluation',

    ABOUT_AVERSA_SECTION: {
      OPEN_FOR_REFERRALS: 'Aversa is closed for referrals',
      DESCRIPTION: 'On this main page you will find key dates, programme information and contact details for queries, along with the slides, video recording and FAQs from the primary care information sessions.',
      WHAT_YOU_CAN_FIND: 'Use the other tabs to find information on:',
      ELIGIBILITY_LABEL: 'Eligibility',
      ELIGIBILITY_LIST_1: 'Inclusion and exclusion criteria (those included in system search and those that are not)',
      ELIGIBILITY_LIST_2: 'Key criteria for discussion with patients',
      REFERRALS_LABEL: 'Referrals',
      REFERRALS_LIST_1: 'Instructions for importing the searches',
      REFERRALS_LIST_2: 'Search downloads (SystmOne and Emis)',
      REFERRALS_LIST_3: 'Instructions for importing the referral form',
      REFERRALS_LIST_4: 'Referral form downloads (SystmOne andEmis)',
      REFERRALS_LIST_5: 'Where to send referrals',
      MEDICATION_ADJUSTMENT_LABEL: 'Medication adjustments & monitoring',
      MEDICATION_ADJUSTMENT_LIST_1: 'Printable flowcharts and case study examples',
      MEDICATION_ADJUSTMENT_LIST_2: 'Blood glucose and blood pressure medication adjustments and thresholds for action',
      MEDICATION_ADJUSTMENT_LIST_3: 'Blood glucose and BP monitoring',
      SERVICE_EVALUATION_LABEL: 'Service evaluation & reporting',
      SERVICE_EVALUATION_LIST_1: 'Data collection schedule for service evaluation (validated measurements and when they’re needed) ',
      SERVICE_EVALUATION_LIST_2: 'Reporting arrangements (information we’ll share with practices)',
      LAUNCH_INFO_1: 'Aversa is scheduled to launch in August 2022.',
      LAUNCH_INFO_2: 'Please wait until advised to start running searches and sending referrals',
      PROGRAMME_INFO_1: 'Programme information can be found on the patient information page ',
      PROGRAMME_INFO_1_LINK: 'https://aversa.changinghealth.com',
      PROGRAMME_INFO_2: 'Please note: As Aversa is a digital programme we don’t provide leaflets or posters.',
      CONTACT_CHANGING_HEALTH: 'Contact Changing Health about Aversa',
      CONTACT_CHANGING_HEALTH_ITEM_1: 'Send enquiries to',
      CONTACT_CHANGING_HEALTH_ITEM_1_EMAIL: 'aversa@changinghealth.com',
      PRIMARY_CARE_INFO_SESSIONS: 'Primary Care Information Sessions',
      PRIMARY_CARE_INFO_SESSIONS_ITEM_1: 'Relevant sections of the recording can also be found on the ‘Eligibility’ and ‘Medication Adjustments’ pages, accessed using the tabs at the top of this page.',
      PRIMARY_CARE_INFO_BACKGROUND: 'Background',
      PRIMARY_CARE_INFO_BACKGROUND_ITEM_1: 'Diabetes remission, Aversa programme design and what’s provided',
      PRIMARY_CARE_INVOLVEMENT: 'Primary Care Involvement',
      PRIMARY_CARE_INVOLVEMENT_ITEM_1: 'Finding and inviting eligible patients, patient information page, referrals and service evaluation.',

      RELEVANT_TIMEPOINTS_REFERENCE_SLIDESET: 'Full slide set with updates/next steps can be found here:',
      RELEVANT_TIMEPOINTS_REFERENCE_SLIDESET_LINK_TEXT: 'Aversa Diabetes Primary Care Session PDF',
      RELEVANT_TIMEPOINTS_REFERENCE_SLIDESET_LINK: '/pdf/aversa_diabetes_primary_care_session.pdf',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS: 'Summary of FAQs from primary care sessions',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_Q_1: '1. How long will the programme recruitment be running?',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_1: 'We’d like to recruit all 200 participants within a 3 month period, so running searches and sending bulk invites is recommended',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_Q_2: '2. Will the programme be running as cohorts?',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_2: 'Participants complete the programme individually, but we’ll organise referrals into cohorts, for ease of product deliveries and organising coaching time. HCPs don’t need to worry about this when referring.',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_Q_3: '3. What happens to referrals when the 200 participants are recruited?',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_3_1: 'We’ve allocated 50 places per CCG area to start with, and we’ll be monitoring referrals as they come in so we can let practices know once we’ve completed recruitment. Anyone already referred will have the option to take part in the NHS England Low Calorie pilot (group based LCD programme) as an alternative.',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_3_2: 'To avoid confusion, we don’t recommend discussing this during referral. Changing Health will handle the relevant discussions if and when required.',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_Q_4: '4. Is there any reimbursement for primary care for the time required for referrals/reviews?',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_1: 'This has been discussed at ICS level for all such programmes. The enhanced weight management referral payment is the only available source of reimbursement.',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_2: 'We recognise and acknowledge the ongoing workload and pressures in primary care and have aimed to mitigate the amount of clinical risk and medication reviews required with careful inclusion criteria and medication adjustment protocols which were developed by a locality clinical working group including primary care colleagues. We fully understand that not all practices will want/be in a position to participate in the Aversa pilot.',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_3: 'We estimate the numbers returned from a search to be around 10% of the practice diabetes population (based on remission research studies), but this will vary from practice to practice.',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_4: 'Remember, low calorie diets are not for everyone. Overall we anticipate that numbers per practice will not be large.',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_5: 'Feedback from NHS England has been that medication adjustments required have been very low using the same protocols.',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_6: 'We hope the ‘ask’ is balanced by what your patients will receive in return, at no cost to them or the NHS, and (we hope) benefit to their ongoing diabetes self-management.',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_6_LIST_1: '12 weeks of meal replacement products (typical cost around £600 per participant, depending on selected plan',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_6_LIST_2: 'Meal replacements for transition, optional weight reset plan and option to continue 1 meal replacement per day after the initial 12 weeks',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_6_LIST_3: 'A full 12 months of a structured online behaviour change programme including education about type 2 diabetes and remission, challenges to try out new positive habits for eating, activity, sleep and setting values-based goals',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_6_LIST_4: 'A variety of evidence based eating plans to try, with accompanying recipes and meal plans',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_6_LIST_5: '12 months of 1:1 telephone support from a behaviour change coach',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_Q_5: '5. Are the meal replacements gluten free?',
      SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_5: 'Yes, allergens are milk and soy. We can\'t support referrals for anyone who follows a vegan diet and cannot/does not eat soy products, as the limited dietary options would not be nutritionally adequate during the low calorie diet phase.',
    },

    ELIGIBILITY_SECTION: {
      PICKED_UP_BY_SYSTEM_SEARCH: '1. Picked up by system search',
      PICKED_UP_BY_SYSTEM_SEARCH_DESCRIPTION: 'The systems search should pick up the following inclusions/exclusions:',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1: 'Low search returns?',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_DESCRIPTION_1: 'Extremely low search returns can be due to varying coding methods in some practices - contact',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_DESCRIPTION_2: 'as a first step for any queries related to search returns.',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_DESCRIPTION_EMAIL: 'aversa@changinghealth.com',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2: 'Inclusions picked up by search',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_1: 'Type 2 diabetes diagnosis ≤ 6 years',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_2: 'Adults aged 20-70y on the date the invitation is received from GP practice',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3: 'BMI',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3_1: '≥ 27kg/㎡ people from White ethnic groups ',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3_2: '≥ 25kg/㎡  people from all other ethnic minority groups',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3_3: 'HbA1c (taken within last 3m). Repeat if likely to have changed significantly \n',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3_4: '48-90mmol/mol without glucose-lowering medication',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3_5: '42-90mmol/mol if on glucose-lowering medication',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3: 'Exclusions picked up by search',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_1: 'BMI >45kg/㎡ (due to likely more complex support needs for long term success)',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_2: 'HbA1c over 90 (for clinical safety when stopping medication). If HbA1c was subsequently reduced could then be referred, if places still available.',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_3: 'Current insulin use',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4: 'One or more significant comorbidities',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_1: 'Active cancer',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_2: 'Heart attack or stroke within last 6 months',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_3: 'Moderate/severe frailty',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_4: 'Severe heart failure (NYHA grade 3 or 4)',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_5: 'Severe renal impairment (most recent eGFR <30mls/min/1.73㎡)',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_6: 'Active liver disease (not including NAFLD)',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_7: 'Porphyria',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_8: 'Previous bariatric surgery',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_9: 'Previous hospitalisation for depression or prescribed antipsychotic medication',
      PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_10: 'Undergoing palliative care',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH: '2. Not picked up by system search',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_DESCRIPTION: 'The following inclusions/exclusions will not be picked up by the systems search. They are listed on the patient information page for self-screening. Please check anything relevant during referral appointment.',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1: 'Inclusion criteria to check (can’t be picked up by search)',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_LIST_1: 'Have access to the internet and a computer, smartphone or tablet',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_LIST_2: 'Ability to read and understand English',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_LIST_3: 'Have attended for monitoring and diabetes review when last invited (including retinal screening) - opportunity to encourage engagement/care processes',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_LIST_4: 'Understands need to continue to attend diabetes reviews, even if remission achieved',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_OTHER: 'Other considerations:',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2: 'Exclusions to check (can’t be picked up by search)',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_1: '3 or more glucose-lowering agents prescribed (due to clinical safety when stopping medications)',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_2: 'Health professional assessment as unable to understand/meet demands or monitoring requirements of programme',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3: 'Unwilling to consider milk/soy-based nutrition drinks',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_4: 'Pregnant/planning pregnancy within next 6 months/breastfeeding',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_5: 'Active substance use disorder',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_6: 'Active eating disorder',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_7: 'Awaiting bariatric surgery (unless prepared to come off waiting list)',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_8: 'Known proliferative retinopathy that has not been treated (unlikely in short duration cohort)',
      NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_9: 'Not able to commit to making long term lifestyle changes',
      OTHER_CONSIDERATIONS: 'Other considerations',
      OTHER_CONSIDERATIONS_ITEM_1: 'Past history of gallstones or gout: discuss increased risk of reoccurrence with significant weight loss (by any means).',

    },
    REFERRALS_SECTION: {
      REFERRALS_PROCESS: 'Referrals process',
      REFERRALS_PROCESS_DESCRIPTION: '[insert video]',

      RECORDS_SEARCH: 'Records Search',
      RECORDS_SEARCH_LIST_ITEM_1_A: 'Instructions for importing SystmOne searches (.docx)',
      RECORDS_SEARCH_LIST_ITEM_1_B: 'Download the SystmOne Aversa search file (.rpt)',
      RECORDS_SEARCH_LIST_ITEM_2_A: 'Instructions for importing EMIS searches (.docx)',
      RECORDS_SEARCH_LIST_ITEM_2_B: 'Download the EMIS Aversa search file (.xml)',

      SEND_SMS: 'Send SMS invites to eligible patients',
      SEND_SMS_INTRO: 'You can optionally screen the list to remove any patients you don\'t feel it is appropriate to invite. This should be based on a specific medical or social reason, not on how well you think they might engage with the programme.',
      SEND_SMS_ITEM_1_A: 'Click here',
      SEND_SMS_ITEM_1_B: 'to download an SMS message template. Please adapt as required for your practice or PCN contact details.',

      REFERRAL_FORMS: 'Referral Forms',
      REFERRAL_FORMS_ITEM_1: 'These are auto-populating referral forms for SystmOne and EMIS Web, to speed up your referral completion.',
      REFERRAL_FORMS_ITEM_1_LIST_ITEM_1: 'You may need to ask your practice System Administrator to import the referral form for you.',
      REFERRAL_FORMS_ITEM_1_LIST_ITEM_2: 'You will not be able to open the form before importing it into your clinical system, as the files are specific for the clinical system.',
      REFERRAL_FORMS_ITEM_1_LIST_ITEM_3: 'Please check the referral form before sending, as not all fields can automatically populate.',
      REFERRAL_FORMS_ITEM_2: 'Please follow the instructions below on importing the referral form to your system.',
      REFERRAL_FORMS_LIST_ITEM_1_A: 'Instructions for importing SystmOne referral form (.docx)',
      REFERRAL_FORMS_LIST_ITEM_1_B: 'Download the SystmOne Aversa referral form file',
      REFERRAL_FORMS_LIST_ITEM_2_A: 'Instructions for importing EMIS referral Form (.docx)',
      REFERRAL_FORMS_LIST_ITEM_2_B: 'Download the EMIS Aversa referral form file',
      REFERRAL_FORMS_LIST_ITEM_3: 'Video guide to completing the Aversa referral and medication adjustment form:',
      REFERRAL_FORMS_LIST_ITEM_3_WARNING: 'AMENDED INFORMATION PLEASE NOTE: Date of diagnosis of type 2 diabetes will need to be manually entered onto the EMIS referral form due to coding variations.',

      PROVIDE_WRITTEN_INSTRUCTIONS_MEDICATION: 'Provide written instructions for medication adjustments',
      PROVIDE_WRITTEN_INSTRUCTIONS_MEDICATION_DESCRIPTION_1: 'Ensure patients are given a copy of the medication adjustment page if they are prescribed any relevant medication',
      PROVIDE_WRITTEN_INSTRUCTIONS_MEDICATION_DESCRIPTION_2: '(even if the advice is to make no changes)',
      PROVIDE_WRITTEN_INSTRUCTIONS_MEDICATION_DESCRIPTION_3: 'Advise patients NOT to make any changes to their medication until the day they start the low calorie diet. They will be reminded of the appropriate time to do this when they start the programme.',
      PROVIDE_WRITTEN_INSTRUCTIONS_MEDICATION_DESCRIPTION_4: 'Please refer to the Meds Adjustment & Monitoring tab for medication adjustment protocols.',
      CHECK_FILLED_IN: 'Check everything’s been filled in',
      CHECK_FILLED_IN_DESCRIPTION: 'It’s an intensive dietary intervention and we need to ensure referrals are appropriate, and that we have all the baseline data needed for service evaluation. Taking a moment to check everything’s on there that should be before sending the referral form to us would be hugely appreciated.',
      EMAIL_REFERRAL_FORM: 'Referrals are closed but if you are sending clinical data please send them to the following email address:',
      EMAIL_REFERRAL_FORM_EMAIL: 'nencicb-nt.aversa.referrals@nhs.net',
      SEND_REFERRAL_FORM: 'Send referral form to Changing Health',
      SEND_REFERRAL_FORM_DESCRIPTION: 'Email referral form to',
    },
    MEDICATION_ADJUSTMENT_SECTION: {
      MEDICATION_ADJUSTMENT_DESCRIPTION: 'Watch the video below for an explanation of the medication adjustments rationale, overview of how to adjust glucose medications and overview of how to adjust blood pressure medications.',
      MEDICATION_ADJUSTMENT_LINK: '[insert video]',
      TIMINGS: 'Timings',
      TIMINGS_1: 'Rationale for meds adjustments: 0 - 2 mins',
      TIMINGS_2: 'Stopping glucose lowering medications: 2 mins - 10 mins',
      TIMINGS_3: 'Restarting glucose lowering medications if needed 10 mins - 11min15s',
      TIMINGS_4: 'Deprescribing blood pressure medications: 11mins15s -16 mins',
      TIMINGS_5: 'Deprescribing examples: 16 mins-18 mins',
      TIMINGS_6: 'Reviewing BP meds if BP too high/low: 18mins - 21mins30s',
      TIMINGS_7: 'Other medications to consider: 21mins 30s -- 22mins 30s',
      PRINTABLE_FLOWCHARTS: 'Printable flowcharts',
      PRINTABLE_FLOWCHARTS_INTRO: 'Advise patients NOT to make any changes to their medication until the day they start the low calorie diet. They will be reminded of the appropriate time to do this when they start the programme.',
      PRINTABLE_FLOWCHARTS_ITEM_1: 'Blood glucose medication',
      PRINTABLE_FLOWCHARTS_ITEM_1_1: 'Flowchart glucose lowering medication adjustments',
      PRINTABLE_FLOWCHARTS_ITEM_1_LIST_1: 'Reminder: not safe for low calorie diet stage',
      PRINTABLE_FLOWCHARTS_ITEM_1_LIST_1_1: 'Sulfonyureas',
      PRINTABLE_FLOWCHARTS_ITEM_1_LIST_1_2: 'SGLT2i',
      PRINTABLE_FLOWCHARTS_ITEM_1_LIST_1_3: 'Meglitinides',
      PRINTABLE_FLOWCHARTS_ITEM_1_LIST_2: 'Safe for low calorie diet stage (if required can be restarted)',
      PRINTABLE_FLOWCHARTS_ITEM_1_LIST_2_1: 'Metformin',
      PRINTABLE_FLOWCHARTS_ITEM_1_LIST_2_2: 'DPP4i',
      PRINTABLE_FLOWCHARTS_ITEM_1_LIST_2_3: 'GLP1 agonists',
      PRINTABLE_FLOWCHARTS_ITEM_2: 'Blood pressure medication',
      PRINTABLE_FLOWCHARTS_ITEM_2_1: 'Flowchart BP lowering medication adjustments',
      PRINTABLE_FLOWCHARTS_ITEM_2_2_LIST_1: 'Quick summary',
      PRINTABLE_FLOWCHARTS_ITEM_2_2_LIST_1_1: 'If BP > 140/90 make no change',
      PRINTABLE_FLOWCHARTS_ITEM_2_2_LIST_1_2: 'If BP within target reduce by 1 agent (reverse order of NICE)',
      PRINTABLE_FLOWCHARTS_ITEM_2_2_LIST_1_3: 'Prioritise stopping agents prescribed for BP lowering only',
      MONITORING: 'Monitoring',
      MONITORING_ITEM_1_LIST_1: 'Blood Glucose',
      MONITORING_ITEM_1_LIST_1_1: 'Provide a meter and 50 strips for anyone on more than metformin (or anyone you think would benefit from monitoring).',
      MONITORING_ITEM_1_LIST_1_2: 'The Aversa platform will prompt weekly monitoring and provide thresholds for GP practice review.',
      MONITORING_ITEM_1_LIST_2: 'Blood pressure (for those on BP lowering agents only)',
      MONITORING_ITEM_1_LIST_2_1: 'Arrange BP review (follow usual practice procedures) for anyone whose BP medication has been reduced or stopped.',
      MONITORING_ITEM_1_LIST_2_2: 'Note main reduction in BP after stopping agents usually occurs after 5-7 weeks of low calorie diet.',
    },
    SERVICE_EVALUATION: {
      DATA_COLLECTION_SCHEDULE: 'Data collection schedule',
      SUMMARY: 'Summary',
      SUMMARY_ITEM_1: 'Referral measurements required',
      SUMMARY_ITEM_1_LIST_1: 'Weight',
      SUMMARY_ITEM_1_LIST_2: 'HbA1c within last 3 months',
      SUMMARY_ITEM_1_LIST_3: 'Blood pressure',
      SUMMARY_ITEM_1_FOOTER: '(All other data autopopulates on referral form)',
      SUMMARY_ITEM_2: 'At 6 months',
      SUMMARY_ITEM_2_LIST_1: 'Weight',
      SUMMARY_ITEM_2_LIST_2: 'HbA1c (should inform treatment decisions)',
      SUMMARY_ITEM_2_LIST_3: 'Blood pressure',
      SUMMARY_ITEM_3: 'At 12 months',
      SUMMARY_ITEM_3_LIST_1: 'Weight',
      SUMMARY_ITEM_3_LIST_2: 'HbA1c',
      SUMMARY_ITEM_3_LIST_3: 'Blood pressure',
      SUMMARY_ITEM_3_LIST_4: 'HCPs asked to complete satisfaction score questionnaire (online, 2 questions)',
      REPORTING: 'Reporting - what you’ll receive from us',
      REPORTING_INTRO: 'We will communicate with you via NHS.net, via the primary contact you have given us or the email address that referred the patient.',
      REPORTING_ITEM_1: 'We will let the referrer know:',
      REPORTING_ITEM_1_LIST_1: 'The referral has been accepted, or if there are any problems',
      REPORTING_ITEM_1_LIST_2: 'The patient has started the programme',
      REPORTING_ITEM_1_LIST_3: 'The patient has left the programme (including completion or if they exit early)',
      REPORTING_ITEM_2: 'We will let the primary contact know:',
      REPORTING_ITEM_2_LIST_1: 'If there has been any adverse events we become aware of through the patient’s participation',
      REPORTING_ITEM_2_LIST_2: 'If there are any problems with the referral that need prompt action',
      REPORTING_OUTRO: 'We will also be in touch at the 6 and 12 month points, requesting a refreshed version of the Referral form, to assess any changes against the first referral baseline (such as updated HbA1C results and medication changes).',
      SENDING_UPDATED_DATA: 'Sending Updated Data',
      SENDING_UPDATED_DATA_ITEM_1: 'We will require updated clinical data from your practice about your patients when they have been on the programme for 3, 6 and 12 months. Book an appointment for your patient, and once the information is on their record please send us the updated information using the autopopulating forms below:',
      SENDING_UPDATED_DATA_ITEM_2_LINK_1: 'SystmOne Patient Update Form',
      SENDING_UPDATED_DATA_ITEM_2_LINK_2: 'EMIS Web  Patient Update Form',

    },
    INFO: {
      WHAT_IS: 'What is Aversa?',
      AVERSA_IS: 'You’re at the start of a 12-week learning experience – built on the collective expertise of dietitians, lifestyle coaches, physiotherapists, and mental wellbeing specialists.',
      AVERSA_IS_1: 'This experience has been carefully designed with learning at its heart to give you the tools to protect your strength, health, and mental wellbeing – so you can continue to live an independent life, long into the future.',
    },
    FORM: {
      REGISTER_FOR_THE_PROGRAMME: 'Register for the programme',
      FIRST_NAME: 'First name',
      LAST_NAME: 'Last name',
      EMAIL: 'Email',
      I_AGREE_TO: 'I agree to',
      CHANGING_HEALTH_TERMS: 'Changing Health\'s Terms',
      AND: 'and',
      AVERSA_TERMS: 'Aversa\'s service description',
      CHANGING_HEALTH: 'Changing Health\'s ',
      PRIVACY_POLICY: 'privacy policy',
      TERMS_CONDITIONS: 'terms & conditions',
      AVERSA: 'Aversa\'s ',
      SERVICE_DESCRIPTIONS: 'service descriptions',
      REGISTER: 'Register',
      BY_FILLING_FORM_YOU_ARE_CONSENTING :'* By filling this form you are consenting to share your personal information with NHS England. Your information will be held confidentially and will not be shared with any third parties. For more information review our ',
      ERROR: {
        CANT_BE_BLANK_MIN_2: "Can't be blank, is too short (minimum is 2 character)",
        TO_LONG_MAX_50: 'Is too long (maximum are 50 characters)',
        EMAIL_ADDRESS_VALID: 'Enter an email address in the correct format, like name@example.com',
        AGREE_PRIVACY_POLICY: 'You will need to agree to the privacy policy, terms and Aversa\'s service to continue with registration.',
        EMAIL_ADDRESS_IN_USE: 'The email address is already in use.',
        USE_A_DIFFERENT_ADDRESS: 'Please use a different email address, or ',
        LOGIN: 'login',
        WITH_EXISTING_ACCOUNT: ' with your existing account.',
        GENERIC: "We weren't able to process your form",
        GENERIC_DESCRIPTION: 'Please try completing and sending the form again. If you are still having problems contact us at ',
      },
    },
    ACCESS_AVAILABLE_ENGLAND: 'Access to this programme is only available in England.',
    YOUR_IP: 'Your IP: ',
    IS_LOCATED_IN: ' is located in ',
    YOURE_TRYING_PROGRAME: 'It looks like you’re trying to sign up to the Aversa programme from outside of England. ',
    IF_YOU_NEED_MORE_HELP: 'If you need more help please contact us at ',
  },
  HEADER: {
    LOGIN: 'Login',
    LOGO_AVERSA: 'Aversa logo',
    LOGO_NHS: 'Healthy NHS logo',
  },
  FOOTER: {
    COOKIE_POLICY: 'Cookie Policy',
    ACCESSIBILITY_STATEMENT: 'Accessibility Statement',
    PRIVACY_POLICY: 'Privacy policy',
    TERMS_AND_CONDITIONS: 'Terms and conditions',
  },
  IMAGE: {
    REFERRAL_PROCESS: 'Referral Process'
  },
  REGISTERED: {
    YOU_ARE_REGISTERED_FOR_AVERSA: 'You are now registered for Aversa',
    THANK_FOR_REGISTERING: 'Thank you for registering with Aversa.',
    OUR_DELIVERY_PARTNER_CH: 'Our delivery partner Changing Health has sent you an email with a link to complete your registration and start your programme.',
    THE_EMAIL_WILL_BE_SEND: 'The email will be sent from the email address ',
    NO_REPLY_EMAIL: 'no-reply@changinghealth.com.',
    THIS_EMAIL_SOMETIMES: 'This email can sometimes end up in your spam or junk folder, so please check there if you can’t see it straightaway.',
    IF_HAVENT_HEARD: 'If you haven’t heard from us in the next 15 minutes, email ',
    FOR_MORE_HELP: ' for more help.',
  }
};

export default translations;

// Whocantakepart?
// AversaDiabetesisavailableinselectedareas.Totakepartyoumusthavereceivedan invitationfrom yourGPpractice.
// Pleasereadthroughthefolowingcriteriawhichhavebeenusedtosendinvitations. Youmaynotknowalofyourresultsortypesofmedication,butyourGPwilcheckthese beforemakingareferal.
// ereferalcriterialistedareforthesafetyofourserviceusers.PleaseonlymakeaGP appointmentforreferaltoAversaDiabetesif,tothebestofyourknowledge,youmatch thefolowingcriteria:
