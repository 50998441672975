import { translate } from 'app/core';
import React from 'react';
import {Label} from "nhsuk-react-components";
import ReactPlayer from "react-player";

const MedicationAdjustmentComponent = (): JSX.Element => {
  const slugTranslate = 'HOME.MEDICATION_ADJUSTMENT_SECTION';
  return (
    <div className="mx-4">
      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-5">
        {translate(`${slugTranslate}.MEDICATION_ADJUSTMENT_DESCRIPTION`)}
      </p>

      <div className='video-wrapper'>
        <ReactPlayer
          className='react-player video-player'
          width='100%'
          height='100%'
          url="https://player.vimeo.com/video/731751542?h=92d6128212&amp;app_id=122963"
          controls
        />
      </div>


      <Label size="s" className='nhsuk-u-margin-top-4'>
        {translate(`${slugTranslate}.TIMINGS`)}
      </Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.TIMINGS_1`)}</li>
        <li>{translate(`${slugTranslate}.TIMINGS_2`)}</li>
        <li>{translate(`${slugTranslate}.TIMINGS_3`)}</li>
        <li>{translate(`${slugTranslate}.TIMINGS_4`)}</li>
        <li>{translate(`${slugTranslate}.TIMINGS_5`)}</li>
        <li>{translate(`${slugTranslate}.TIMINGS_6`)}</li>
        <li>{translate(`${slugTranslate}.TIMINGS_7`)}</li>
      </ul>

      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        <span className="nhsuk-u-font-weight-bold purple">
          {translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_INTRO`)}
        </span>
      </p>
      <Label size="s" className='nhsuk-u-margin-top-4'>
        {translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_1`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-5">
        <a href='/assets/flowchart_glucose_lowering_medication_adjustments.pdf' className='nhsuk-link purple' target="_blank" rel="noopener noreferrer">{translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_1_1`)}</a>
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>
          {translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_1_LIST_1`)}
          <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
            <li>{translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_1_LIST_1_1`)}</li>
            <li>{translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_1_LIST_1_2`)}</li>
            <li>{translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_1_LIST_1_3`)}</li>
          </ul>
        </li>
        <li>
          {translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_1_LIST_2`)}
          <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
            <li>{translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_1_LIST_2_1`)}</li>
            <li>{translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_1_LIST_2_2`)}</li>
            <li>{translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_1_LIST_2_3`)}</li>
          </ul>
        </li>
      </ul>
      <Label size="s" className='nhsuk-u-margin-top-4'>
        {translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_2`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-5">
        <a href='/assets/flowchart_bp_lowering_medication_adjustments.pdf' className='nhsuk-link purple' target="_blank" rel="noopener noreferrer">{translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_2_1`)}</a>
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>
          {translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_2_2_LIST_1`)}
          <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
            <li>{translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_2_2_LIST_1_1`)}</li>
            <li>{translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_2_2_LIST_1_2`)}</li>
            <li>{translate(`${slugTranslate}.PRINTABLE_FLOWCHARTS_ITEM_2_2_LIST_1_3`)}</li>
          </ul>
        </li>
      </ul>
      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.MONITORING`)}
      </Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>
          {translate(`${slugTranslate}.MONITORING_ITEM_1_LIST_1`)}
          <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
            <li>{translate(`${slugTranslate}.MONITORING_ITEM_1_LIST_1_1`)}</li>
            <li>{translate(`${slugTranslate}.MONITORING_ITEM_1_LIST_1_2`)}</li>
          </ul>
        </li>
      </ul>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>
          {translate(`${slugTranslate}.MONITORING_ITEM_1_LIST_2`)}
          <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
            <li>{translate(`${slugTranslate}.MONITORING_ITEM_1_LIST_2_1`)}</li>
            <li>{translate(`${slugTranslate}.MONITORING_ITEM_1_LIST_2_2`)}</li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default MedicationAdjustmentComponent;
