import { translate } from 'app/core';
import { Label } from 'nhsuk-react-components';
import React from 'react';

const EligibilityComponent = (): JSX.Element => {
  const slugTranslate = 'HOME.ELIGIBILITY_SECTION';
  return (
    <div className="mx-4">

      <Label size="s" className='nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_DESCRIPTION_1`)}
        &nbsp;
        <a href={`mailto:${translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_DESCRIPTION_EMAIL`)}`} className='nhsuk-link purple'>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_DESCRIPTION_EMAIL`)}</a>
        &nbsp;
        {translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_DESCRIPTION_2`)}
      </p>
        
      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_DESCRIPTION`)}
      </p>

      <Label size="s" className='nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2`)}
      </Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_2`)}</li>
        <li>
          {translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3`)}
          <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3_1`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3_2`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3_3`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3_4`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3_5`)}</li>
          </ul>
        </li>
      </ul>

      <Label size="s" className='nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3`)}
      </Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_3`)}</li>
        <li>
          {translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4`)}
          <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_1`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_2`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_3`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_4`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_5`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_6`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_7`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_8`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_9`)}</li>
            <li>{translate(`${slugTranslate}.PICKED_UP_BY_SYSTEM_SEARCH_ITEM_3_LIST_4_10`)}</li>
          </ul>
        </li>
      </ul>

      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_DESCRIPTION`)}
      </p>

      <Label size="s" className='nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1`)}
      </Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_LIST_2`)}</li>
      </ul>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_OTHER`)}
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_LIST_3`)}</li>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_1_LIST_4`)}</li>
      </ul>
        
      <Label size="s" className='nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2`)}
      </Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_3`)}</li>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_4`)}</li>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_5`)}</li>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_6`)}</li>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_7`)}</li>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_8`)}</li>
        <li>{translate(`${slugTranslate}.NOT_PICKED_UP_BY_SYSTEM_SEARCH_ITEM_2_LIST_9`)}</li>
      </ul>
      <Label size="s" className='nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.OTHER_CONSIDERATIONS`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.OTHER_CONSIDERATIONS_ITEM_1`)}
      </p>
        
    </div>
  );
};

export default EligibilityComponent;
