import { translate } from 'app/core';
import {Label, Row} from 'nhsuk-react-components';
import React from 'react';
import ReactPlayer from "react-player";
import icsLogo from "../../../../assets/images/icb-logo.jpg";

const AboutAversaComponent = (): JSX.Element => {
  const slugTranslate = 'HOME.ABOUT_AVERSA_SECTION';
  return (
    <div className="mx-4">
      <Row className='container-ics-logo nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-4'>
        <img className="nhsuk-u-margin-right-3" src={icsLogo} alt={translate('HEADER.LOGO_AVERSA')} />
      </Row>
      <p className="nhsuk-heading-l nhsuk-u-font-weight-bold purple nhsuk-u-margin-bottom-6">
        {translate(`${slugTranslate}.OPEN_FOR_REFERRALS`)}
      </p>
        
      <p className="nhsuk-body">
        {translate(`${slugTranslate}.DESCRIPTION`)}
      </p>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHAT_YOU_CAN_FIND`)}
      </p>
        
      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.ELIGIBILITY_LABEL`)}
      </Label>
      <ul className='nhsuk-list--bullet'>
        <li>{translate(`${slugTranslate}.ELIGIBILITY_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.ELIGIBILITY_LIST_2`)}</li>
      </ul>

      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.REFERRALS_LABEL`)}
      </Label>
      <ul className='nhsuk-list--bullet'>
        <li>{translate(`${slugTranslate}.REFERRALS_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.REFERRALS_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.REFERRALS_LIST_3`)}</li>
        <li>{translate(`${slugTranslate}.REFERRALS_LIST_4`)}</li>
        <li>{translate(`${slugTranslate}.REFERRALS_LIST_5`)}</li>
      </ul>

      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.MEDICATION_ADJUSTMENT_LABEL`)}
      </Label>
      <ul className='nhsuk-list--bullet'>
        <li>{translate(`${slugTranslate}.MEDICATION_ADJUSTMENT_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.MEDICATION_ADJUSTMENT_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.MEDICATION_ADJUSTMENT_LIST_3`)}</li>
      </ul>

      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.SERVICE_EVALUATION_LABEL`)}
      </Label>
      <ul className='nhsuk-list--bullet'>
        <li>{translate(`${slugTranslate}.SERVICE_EVALUATION_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.SERVICE_EVALUATION_LIST_2`)}</li>
      </ul>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-4">
        {translate(`${slugTranslate}.PROGRAMME_INFO_1`)}
        <a href={`${translate(`${slugTranslate}.PROGRAMME_INFO_1_LINK`)}`} className='nhsuk-link purple' target="_blank" rel="noopener noreferrer">here</a>
        .
        <br />
        {translate(`${slugTranslate}.PROGRAMME_INFO_2`)}
      </p>

      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.CONTACT_CHANGING_HEALTH`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.CONTACT_CHANGING_HEALTH_ITEM_1`)}
        &nbsp;
        <a href={`mailto:${translate(`${slugTranslate}.CONTACT_CHANGING_HEALTH_ITEM_1_EMAIL`)}`} className='nhsuk-link purple'>{translate(`${slugTranslate}.CONTACT_CHANGING_HEALTH_ITEM_1_EMAIL`)}</a>
      </p>

      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.PRIMARY_CARE_INFO_SESSIONS`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.PRIMARY_CARE_INFO_SESSIONS_ITEM_1`)}
      </p>

      <Label size="s" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.PRIMARY_CARE_INFO_BACKGROUND`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-3">
        {translate(`${slugTranslate}.PRIMARY_CARE_INFO_BACKGROUND_ITEM_1`)}
      </p>
      <div className='video-wrapper nhsuk-u-margin-bottom-3'>
        <ReactPlayer
          className='react-player video-player'
          width='100%'
          height='100%'
          url="https://player.vimeo.com/video/731750326?h=5658a9676c&amp;app_id=122963"
          controls
        />
      </div>

      <Label size="s" className='purple nhsuk-u-margin-top-9 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.PRIMARY_CARE_INVOLVEMENT`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.PRIMARY_CARE_INVOLVEMENT_ITEM_1`)}
      </p>
      <div className='video-wrapper'>
        <ReactPlayer
          className='react-player video-player'
          width='100%'
          height='100%'
          url="https://player.vimeo.com/video/731751331?h=5a2e4d6fdc&amp;app_id=122963"
          controls
        />
      </div>

      <Label size="s" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.RELEVANT_TIMEPOINTS_REFERENCE_SLIDESET`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-font-weight-bold nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-4">
        <a href="/assets/aversa_diabetes_primary_care_session.pdf" className='nhsuk-link' target="_blank">{translate(`${slugTranslate}.RELEVANT_TIMEPOINTS_REFERENCE_SLIDESET_LINK_TEXT`)}</a>
      </p>

      <Label size="m" className='purple nhsuk-u-margin-top-6 nhsuk-u-margin-bottom-2'>
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS`)}
      </Label>
      <Label size="s" className='nhsuk-u-margin-bottom-2 nhsuk-u-margin-top-5'>
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_Q_1`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_1`)}
      </p>
      <Label size="s" className='nhsuk-u-margin-bottom-2 nhsuk-u-margin-top-5'>
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_Q_2`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_2`)}
      </p>
      <Label size="s" className='nhsuk-u-margin-bottom-2 nhsuk-u-margin-top-5'>
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_Q_3`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_3_1`)}
        <br />
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_3_2`)}
      </p>
      <Label size="s" className='nhsuk-u-margin-bottom-2 nhsuk-u-margin-top-5'>
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_Q_4`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_1`)}
        <br />
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_2`)}
        <br />
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_3`)}
        <br />
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_4`)}
        <br />
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_5`)}
        <br />
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_6`)}
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_6_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_6_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_6_LIST_3`)}</li>
        <li>{translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_6_LIST_4`)}</li>
        <li>{translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_4_6_LIST_5`)}</li>
      </ul>
      <Label size="s" className='nhsuk-u-margin-bottom-2 nhsuk-u-margin-top-5'>
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_Q_5`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.SUMMARY_OF_FAQS_PRIMARY_CARE_SESSIONS_A_5`)}
      </p>

    </div>
  );
};

export default AboutAversaComponent;
